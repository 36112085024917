import { SolanaPage } from "@components/nav/SolanaPage";
import { Solana } from "@luma-team/shared";
import { LuxInputField } from "@lux/atoms/components/LuxInput";
import { ZmClient } from "@utils/ZmClient";
import { Form, Formik } from "formik";
import React from "react";
import { LuxSubmitButton } from "@lux/atoms/components/LuxSubmitButton";

/**
 * We need to deploy something to glow.new to keep the domain, so that's
 * what this is...
 */
export const PhantomDotNewPage = () => {
  return (
    <SolanaPage title="Create an NFT">
      <div className="zm-container p-5 mx-auto" style={{ maxWidth: 350 }}>
        <h2>Create an NFT</h2>

        <div>
          <Formik
            initialValues={{
              name: "",
              image: "",
            }}
            onSubmit={async ({ name, image }) => {
              const { address } = await window.glow!.connect();
              const network = Solana.Network.Mainnet;

              const { transaction_base64 } = await ZmClient.post(
                "/nftoken/construct-nft-create-tx",
                {
                  network,
                  wallet: address,
                  name,
                  image,
                  collection: null,
                },
              );

              await window.glow!.signAndSendTransaction({
                network,
                transactionBase64: transaction_base64,
                waitForConfirmation: true,
              });
            }}
          >
            <Form>
              <LuxInputField
                name={"name"}
                className={"mb-3"}
                label={"Name"}
                inputProps={{ style: { maxWidth: 200 } }}
              />

              {/*<SimpleDropZoneField*/}
              {/*  label={"NFT Image"}*/}
              {/*  size={300}*/}
              {/*  ZmClient={ZmClient}*/}
              {/*  destination={{ bucket: "cdn.glow.app", folder: "n" }}*/}
              {/*  className={"mb-3"}*/}
              {/*  name={"image"}*/}
              {/*/>*/}

              <LuxSubmitButton label={"Create NFT"} size={"small"} />
            </Form>
          </Formik>
        </div>
      </div>
    </SolanaPage>
  );
};
