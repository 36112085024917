import React from "react";
import GlowLogoSvg from "@glow/react/icons/GlowLogo.svg";
import { ResponsiveBreakpoint } from "@lux/atoms/utils/StyleConstants";

export const Logo = () => {
  return (
    <div className="logo">
      <GlowLogoSvg />

      <style jsx>{`
        .logo {
          position: absolute;
          left: 0;
          top: 0;

          @media (max-width: ${ResponsiveBreakpoint.medium}) {
            position: relative;
            padding: 1.5rem;
            padding-bottom: 0;
          }

          @media (max-width: ${ResponsiveBreakpoint.small}) {
            padding: 1rem;
            padding-bottom: 0;
          }

          @media (max-width: ${ResponsiveBreakpoint.tiny}) {
            padding: 0.5rem;
            padding-bottom: 0;
          }

          :global(svg) {
            width: 60px;
            height: auto;

            @media (max-width: ${ResponsiveBreakpoint.tiny}) {
              width: 48px;
            }
          }
        }
      `}</style>
    </div>
  );
};
