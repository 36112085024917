import { ResponsiveBreakpoint } from "@lux/atoms/utils/StyleConstants";
import StakeIcon from "@glow/react/icons/StakeFilled.svg";
import React from "react";
import { SectionImage } from "./SectionImage";
import { SectionTitle } from "./SectionTitle";

export const Stake = () => {
  return (
    <div className="stake relative">
      <SectionTitle
        title="Stake and Earn SOL"
        icon={<StakeIcon />}
        color="var(--purple-50)"
      />
      <SectionImage
        src="/landing/sections/stake/stake.png"
        sourceWidth={1976}
        sourceHeight={1421}
        width="100%"
        height="72%"
        className="relative info light-only"
      />
      <SectionImage
        src="/landing/sections/stake/stake-dark.png"
        sourceWidth={1976}
        sourceHeight={1421}
        width="100%"
        height="72%"
        className="relative info dark-only"
      />

      <style jsx>{`
        .stake {
          :global(.info) {
            margin-top: -1.5rem;

            @media (max-width: ${ResponsiveBreakpoint.medium}) {
              margin-top: -0.8rem;
            }
          }
        }
      `}</style>
    </div>
  );
};
