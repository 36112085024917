import ActivityIcon from "@glow/react/icons/Activity.svg";
import React from "react";
import { SectionImage } from "./SectionImage";
import { SectionTitle } from "./SectionTitle";

export const Activity = () => {
  return (
    <div className="activity">
      <SectionTitle
        title="All your activity. In one place."
        icon={<ActivityIcon />}
        color="var(--orange-50)"
      />
      <div className="flex-center-center">
        <SectionImage
          src="/landing/sections/activity/activity.png"
          sourceWidth={1200}
          sourceHeight={1160}
          width="70%"
          height="67%"
          className="relative light-only"
        />
        <SectionImage
          src="/landing/sections/activity/activity-dark.png"
          sourceWidth={1200}
          sourceHeight={1160}
          width="70%"
          height="67%"
          className="relative dark-only"
        />
      </div>
    </div>
  );
};
