import classNames from "classnames";
import { Field, FieldProps, getIn, useFormikContext } from "formik";
import { FieldAttributes } from "formik/dist/Field";
import React, {
  ClipboardEventHandler,
  ForwardedRef,
  forwardRef,
  KeyboardEventHandler,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import TextareaAutosize from "react-textarea-autosize";

import { LuxInputProps, LuxInputWrapper } from "./_LuxInputWrapper";

export type LuxTextareaRef = {
  blur: () => void;
  focus: () => void;
};

export type LuxTextareaProps = LuxInputProps & {
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  autoFocus?: boolean;
  minRows?: number;
  maxRows?: number;
  allowNewline?: boolean;
  onKeyDown?: KeyboardEventHandler<HTMLTextAreaElement>;
  onPaste?: ClipboardEventHandler<HTMLTextAreaElement>;
  textAreaProps?: React.ComponentPropsWithoutRef<typeof TextareaAutosize>;
};

export const LuxTextarea = forwardRef(
  (
    {
      label,
      className,
      placeholder,
      value,
      onChange,
      size = "medium",
      helperText,
      disabled,
      rounded,
      autoFocus,
      variant = "outline",
      minRows = 1,
      maxRows,
      error,
      allowNewline = true,
      onKeyDown,
      onPaste,
      textAreaProps,
    }: LuxTextareaProps,
    ref: ForwardedRef<LuxTextareaRef>,
  ) => {
    const inputRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
      if (autoFocus) {
        inputRef.current?.focus();
      }
    }, [autoFocus]);

    useImperativeHandle(ref, () => ({
      focus: () => {
        inputRef.current?.focus();
      },
      blur: () => {
        inputRef.current?.blur();
      },
    }));

    return (
      <LuxInputWrapper
        label={label}
        size={size}
        rounded={rounded}
        variant={variant}
        helperText={helperText}
        className={className}
        error={error}
      >
        <TextareaAutosize
          ref={inputRef as any}
          placeholder={placeholder}
          minRows={minRows}
          maxRows={maxRows}
          disabled={disabled}
          value={value}
          onChange={(e) => {
            let val = e.target.value;
            if (!allowNewline) {
              val = val.replace(/\r?\n|\r/g, "");
            }
            onChange(val);
          }}
          onKeyDown={onKeyDown}
          onPaste={onPaste}
          {...(textAreaProps || {})}
          className={classNames(
            "luma-input",
            { "luma-input-scrollable": Boolean(maxRows) },
            textAreaProps?.className,
          )}
        />
      </LuxInputWrapper>
    );
  },
);

export const LuxTextareaField = forwardRef(
  (
    {
      name,
      label,
      size = "medium",
      allowNewline = true,
      required,
      helperText,
      validate,
      fieldProps,
      onKeyDown,
      ...props
    }: {
      name: string;
      required?: boolean;
      fieldProps?: Omit<FieldAttributes<any>, "name" | "validate">;
      validate?: (
        value: string | null,
      ) => Promise<string | undefined> | string | undefined;
    } & Omit<LuxTextareaProps, "value" | "onChange" | "error" | "disabled">,
    ref: ForwardedRef<LuxTextareaRef>,
  ) => {
    const { values, setFieldValue, isSubmitting, submitCount, submitForm } =
      useFormikContext();

    return (
      <Field
        {...fieldProps}
        name={name}
        validate={
          validate
            ? validate
            : (value: null | string) => {
                if ((value == null || value === "") && required) {
                  return "This field is required.";
                }

                return undefined;
              }
        }
      >
        {({ field, meta }: FieldProps<string>) => {
          const changed = Boolean(
            field.value !== meta.initialValue && meta.touched,
          );
          const showError = Boolean(meta.error && (submitCount || changed));
          return (
            <LuxTextarea
              allowNewline={allowNewline}
              disabled={isSubmitting}
              error={showError}
              label={label}
              onChange={(value: string) => setFieldValue(name, value)}
              ref={ref}
              size={size}
              value={getIn(values, name)}
              {...props}
              helperText={showError ? meta.error : helperText}
              onKeyDown={
                onKeyDown ||
                ((e) => {
                  if (e.key === "Enter" && e.metaKey) {
                    e.preventDefault();
                    submitForm();
                  }
                })
              }
            />
          );
        }}
      </Field>
    );
  },
);
