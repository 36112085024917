import { ResponsiveBreakpoint } from "@lux/atoms/utils/StyleConstants";
import FireIcon from "@glow/react/icons/FireFilled.svg";
import React from "react";
import { SectionTitle } from "./SectionTitle";

export const Burn = () => {
  return (
    <div className="burn relative">
      <SectionTitle
        title={
          <span>
            Received spam tokens?
            <br />
            Burn them and earn SOL.
          </span>
        }
        icon={<FireIcon />}
        color="var(--red-50)"
      />
      <div className="flex-center-center devices relative">
        <div className="ellipse" />
        <div className="device-video">
          <div className="inner">
            <video
              src="/landing/sections/burn/burn.mp4"
              muted
              playsInline
              loop
              autoPlay
              className="light-only"
            />
            <video
              src="/landing/sections/burn/burn-dark.mp4"
              muted
              playsInline
              loop
              autoPlay
              className="dark-only"
            />
          </div>
          <div className="mask light-only" />
          <div className="mask dark-only" />
        </div>
      </div>

      <style jsx>{`
        .burn {
          padding-bottom: 3.5rem;

          @media (max-width: ${ResponsiveBreakpoint.medium}) {
            padding-bottom: 2.375rem;
          }

          .ellipse {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 25vw;
            height: 25vw;
            max-width: 365px;
            max-height: 365px;
            border-radius: 50%;
            background: var(--red);
            transform: translate(-50%, -50%);

            @media (max-width: ${ResponsiveBreakpoint.medium}) {
              width: 50vw;
              height: 50vw;
            }
          }

          .device-video {
            width: 34%;
            padding-top: 69%;
            position: relative;
            overflow: hidden;

            $maskDecrease: 0.8vw;
            $maskDecreaseMobile: 1.5vw;

            .inner {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              width: calc(100% - min(24px, ($maskDecrease * 2)));
              height: calc(100% - min(24px, ($maskDecrease * 2)));
              overflow: hidden;

              @media (max-width: ${ResponsiveBreakpoint.medium}) {
                width: calc(100% - ($maskDecreaseMobile * 2));
                height: calc(100% - ($maskDecreaseMobile * 2));
              }
            }

            video {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }

            .mask {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background: url(/landing/sections/burn/device-mask.png) left top
                no-repeat;
              background-size: contain;

              &.dark-only {
                background-image: url(/landing/sections/burn/device-mask-dark.png);
              }
            }
          }

          :global(.device) {
            transform: translateZ(0px);
            perspective: 1000;
            backface-visibility: hidden;

            filter: drop-shadow(
                61.5247px 107.452px 74.5229px rgba(0, 0, 0, 0.02)
              )
              drop-shadow(27.7294px 47.66px 54.5923px rgba(0, 0, 0, 0.04))
              drop-shadow(6.93236px 12.1316px 30.3291px rgba(0, 0, 0, 0.05));

            @media (max-width: ${ResponsiveBreakpoint.medium}) {
              filter: drop-shadow(
                  36.8656px 64.3849px 44.654px rgba(0, 0, 0, 0.02)
                )
                drop-shadow(16.6155px 28.5578px 32.7117px rgba(0, 0, 0, 0.04))
                drop-shadow(4.15386px 7.26926px 18.1732px rgba(0, 0, 0, 0.05));
            }
          }
        }
      `}</style>
    </div>
  );
};
