import { ResponsiveBreakpoint } from "@lux/atoms/utils/StyleConstants";
import ExploreIcon from "@glow/react/icons/Explore.svg";
import React from "react";
import { SectionImage } from "./SectionImage";
import { SectionTitle } from "./SectionTitle";

export const Explore = () => {
  return (
    <div className="explore relative">
      <SectionTitle
        title="Effortlessly Explore NFTs"
        icon={<ExploreIcon />}
        color="var(--green-50)"
      />
      <div className="justify-end devices">
        <SectionImage
          src="/landing/sections/explore/device-1.png"
          sourceWidth={654}
          sourceHeight={1322}
          width="32%"
          height="65%"
          className="relative device mr-4 light-only"
        />
        <SectionImage
          src="/landing/sections/explore/device-1-dark.png"
          sourceWidth={654}
          sourceHeight={1322}
          width="32%"
          height="65%"
          className="relative device mr-4 dark-only"
        />
        <SectionImage
          src="/landing/sections/explore/device-2.png"
          sourceWidth={654}
          sourceHeight={1322}
          width="32%"
          height="65%"
          className="relative device mr-4 light-only"
        />
        <SectionImage
          src="/landing/sections/explore/device-2-dark.png"
          sourceWidth={654}
          sourceHeight={1322}
          width="32%"
          height="65%"
          className="relative device mr-4 dark-only"
        />
        <SectionImage
          src="/landing/sections/explore/device-3.png"
          sourceWidth={390}
          sourceHeight={1322}
          width="19%"
          height="65%"
          className="relative device light-only"
        />
        <SectionImage
          src="/landing/sections/explore/device-3-dark.png"
          sourceWidth={390}
          sourceHeight={1322}
          width="19%"
          height="65%"
          className="relative device dark-only"
        />
      </div>

      <style jsx>{`
        .explore {
          .devices {
            margin-bottom: 5.375rem;

            @media (max-width: ${ResponsiveBreakpoint.medium}) {
              margin-bottom: 2.875rem;
            }
          }

          :global(.device) {
            transform: translateZ(0px);
            perspective: 1000;
            backface-visibility: hidden;

            filter: drop-shadow(
                8.75408px 29.1803px 18.4808px rgba(0, 0, 0, 0.03)
              )
              drop-shadow(3.8907px 12.6448px 13.6175px rgba(0, 0, 0, 0.06))
              drop-shadow(0.972675px 2.91803px 7.7814px rgba(0, 0, 0, 0.07));

            @media (max-width: ${ResponsiveBreakpoint.medium}) {
              filter: drop-shadow(
                  4.66916px 15.5639px 9.85711px rgba(0, 0, 0, 0.03)
                )
                drop-shadow(2.07518px 6.74434px 7.26313px rgba(0, 0, 0, 0.06))
                drop-shadow(0.518795px 1.55639px 4.15036px rgba(0, 0, 0, 0.07))
                drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.07));
            }
          }
        }
      `}</style>
    </div>
  );
};
